import useSWR from "swr";
import { PromptTemplatesResponse } from "../pages/api/prompt-template";
import { AppError } from "../utils/app-error";
import { fetchApi } from "../services/fetch-api";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useSettings } from "./use-settings";
import { PromptTemplateDTO } from "../database/entity/PromptTemplate";
import { logger } from "../utils/logger";
import { rolesV2 } from "../services/prompts/templates-v2/roles";
import { quizzesV2 } from "../services/prompts/templates-v2/quizzes";
import { topicsV2 } from "../services/prompts/templates-v2/topics";
import { siteConfig } from "../configuration/config";
import { casesV2 } from "../services/prompts/templates-v2/cases";

export function usePromptTemplates() {
  const { settings } = useSettings();
  const router = useRouter();
  const [loadingState, setLoadingState] = useState<"init" | "loading" | "loaded" | "error">("init");
  const [translatedPromptTemplates, setTranslatedPromptTemplates] = useState<PromptTemplateDTO[]>(
    [],
  );
  const response = useSWR<PromptTemplatesResponse, AppError>("/prompt-template", fetchApi);

  const promptTemplates = useMemo(() => {
    if (response.isLoading) {
      return [];
    }

    const templates = response.data?.promptTemplates || [];
    const defaultTemplates = [...rolesV2, ...quizzesV2, ...topicsV2, ...casesV2];

    return response.data?.enableDefaultPromptTemplates
      ? templates.concat(defaultTemplates)
      : templates;
  }, [response.data, response.isLoading]);

  // todo: consider making sure this does not rerun when modal is reopened (context?)
  useEffect(() => {
    if (
      !promptTemplates ||
      loadingState === "loading" ||
      loadingState === "loaded" ||
      response.isLoading
    ) {
      return;
    }

    setLoadingState("loading");

    const fromLanguage = siteConfig.customContent?.baseLanguage || "en-US";
    const toLanguage = settings.uiLanguage;

    if (fromLanguage === toLanguage) {
      logger.success("No need to translate prompt templates");
      setTranslatedPromptTemplates(promptTemplates);
      setLoadingState("loaded");
      return;
    }

    logger.verbose("Fetching prompt template translations");

    fetchApi<PromptTemplateDTO[]>("/translator/translate-prompt-template", {
      method: "POST",
      body: JSON.stringify({
        promptTemplates,
        from: fromLanguage,
        to: toLanguage,
      }),
    })
      .then((t) => {
        logger.verbose("Prompt template translations fetched successfully");
        setTranslatedPromptTemplates(t);
      })
      .catch(() => {
        logger.error("Failed to translate prompt templates. Return original templates.");
        setTranslatedPromptTemplates(promptTemplates);
      })
      .finally(() => {
        setLoadingState("loaded");
      });
  }, [promptTemplates, settings, response.isLoading, loadingState]);

  const currentTemplate = useMemo(() => {
    return promptTemplates.find((f) => f.slug === router.query.t);
  }, [promptTemplates, router.query.t]);

  return {
    ...response.data,
    currentTemplate,
    promptTemplates,
    translatedPromptTemplates,
    promptTemplatesAreLoading: loadingState === "init" || loadingState === "loading",
    includeDefaultTemplates: true,
  };
}
